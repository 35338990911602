<template>
<div class=" justify-content-center align-items-center w-100 px-1">
    <header class="jumbotron">
        <div class="inline-flex d-inline">
            <h3 class="d-inline">
                <strong>Edit User:</strong>
            </h3>
            <p class="d-inline pl-3">{{userModal.first_name}} {{userModal.last_name}}</p>
             <button  type="button" class="btn btn-danger d-inline mr-4 float-right" @click="deleteUser()">Delete User</button>
        </div>
    </header>
    <div class="d-flex justify-content-center w-100">
        <div style="max-width: 600px;">
            <div class="background-form-group">
                 <form>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">First Name</label>
                                    <input type="text" class="form-control" id="inputFName" placeholder="John" v-model="userModal.first_name">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Last Name</label>
                                    <input type="text" class="form-control" id="inputLName" placeholder="Doe" v-model="userModal.last_name">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Email</label>
                                <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" v-model="userModal.email">
                            </div>
                             <div class="form-group">
                            <label for="inputRole">Role</label>
                            <select class="form-control" id="userRole" v-model="userModal.role">
                              <option v-for="(role,index) in roles" :key="role.id" :value="role.id">{{capitalizeFirstLetter(role.name)}}</option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label for="inputRestaurant">Restaurant</label>
                              <select class="form-control" id="userRestaurant" v-model="userModal.restaurant_id">
                            <option :value="null">All Restaurants</option>
                              <option v-for="(restaurant,index) in restaurants" :key="restaurant.id" :value="restaurant.id">{{restaurant.name}}</option>
                            </select>
                          </div>
                             <div class="form-group">
                                <label for="inputPassword">Password</label>
                                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" v-model="userModal.password">
                              </div>
                                      <div class="text-center">
                        <button  type="button" class="btn btn-primary" @click="updateUserInfo()">Save</button>
                    </div>
                        </form>
                        
            </div>
        </div>

    </div>
</div>
</template>
<script>
import UserService from '../services/user.service';

export default {
    name: 'editUser',
    data() {
        return {
            roles: [],
            positions: [],
            areas: [],
            restaurants: [],
            userModal: {
                first_name: null,
                last_name: null,
                email: null,
                restaurant_id: null,
                role: null,
                password:null,
            }
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        idToPosition(id) {
            if (this.positions.length > 0) {

                return this.positions.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
        idToRestaurant(id) {
            if (this.restaurants.length > 0) {
                return this.restaurants.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
        getPositionList(id){
            UserService.getPositionList(id).then(
            response => {
                this.positions = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        },
        backOut(){
            this.$router.back()
        },
        deleteUser(){
            UserService.deleteDailyReport(this.$route.params.id).then(
            response => {
             this.$router.back()
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        },
        updateUserInfo(){
            let userInfo = {...this.userModal}
            UserService.updateUserInfo(userInfo).then(
            response => {
             this.$router.back()
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        },
        getUserInfo(id){
            UserService.getUserInfo(id).then(
            response => {
             let element =response.data
             this.userModal =  {id:element.id, first_name:element.first_name,last_name:element.last_name,restaurant_id:element.restaurant_id,role:element.roles[0]?.id, email:element.email}
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getRestaurantList(){
            UserService.getRestaurantsList().then(
            response => {
                this.restaurants = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        this.getUserInfo(this.$route.params.id);
        UserService.getRoleList().then(
            response => {
                this.roles = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        this.getRestaurantList();
        
    }
};
</script>
<style scoped>
.background-form-group{
    padding: 20px;
    background: #E8E8E8;
    border-radius: 8px;

}
</style>